import { createContext, useContext, useEffect, useReducer } from 'react';
import {
	DEFAULT,
	StateType,
	UnifiedDashboardState_Action,
	reducer,
} from './unified-dashboard.state';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UnifiedComponentPlugin } from './unified-dashboard.adapter';
import { INTEGRATED_WITH_UP } from 'constants/constants';
import { login } from 'states/userSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { populateLegacyLoginContext } from '../../services/auth.service';
import { LegacyComponentPlugin } from 'packages/legacy/legacy-dashboard.adapter';

export const UnifiedDashboardCtx = createContext<{
	state: StateType;
	dispatch: React.Dispatch<UnifiedDashboardState_Action>;
}>({ state: DEFAULT, dispatch: () => {} });

export function UnifiedDashboardPluginComponent({ children }: any) {
	const [state, dispatch] = useReducer(reducer, DEFAULT);
	return (
		<UnifiedDashboardCtx.Provider value={{ state, dispatch }}>
			{children}
		</UnifiedDashboardCtx.Provider>
	);
}

function LegacyAuthWrapper({ children }: any) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);

	useEffect(() => {
		if (!user?.userAuth || user?.userAuth?.length <= 0) {
			LegacyComponentPlugin.queryLocalSession().then((res) => {
				if (!res.success || !res.data) {
					navigate('/login');
					return;
				}
				dispatch(login(res.data));
			});
		}
	}, []);

	return children;
}

function UnifiedAuthWrapper({ children }: any) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const ctx = useContext(UnifiedDashboardCtx);
	const { pathname } = useLocation();
	const [searchParams, _] = useSearchParams();

	const token = searchParams.get('accessToken');

	useEffect(() => {
		if (pathname === '/verify' && token) {
			return;
		}
		if (!ctx?.state.mftAccessToken || ctx?.state.mftAccessToken.length <= 0) {
			UnifiedComponentPlugin.queryLocalSession().then((res) => {
				if (!res.success || !res.data) {
					navigate('/login');
					return;
				}

				ctx.dispatch({
					type: 'UNIFIED_DASHBOARD_LOAD_SESSION',
					payload: res.data,
				});
				dispatch(login(populateLegacyLoginContext(res.data)));
			});
		}
	}, []);
	return children;
}

export function ClientContainer({ children }: any) {
	if (INTEGRATED_WITH_UP)
		return <UnifiedAuthWrapper>{children}</UnifiedAuthWrapper>;
	return <LegacyAuthWrapper>{children}</LegacyAuthWrapper>;
}
